<template>
  <div class="px-5" id="member-renew-page">
    <b-row>
      <b-col lg="12">
        <b-card class="login-body mb-5">
            <div class="h-100 d-flex align-items-center text-center flex-wrap justify-content-center" v-if="isAuthenticated === true">
                <h3 v-if="isOk === null" class="mt-auto">Jag vill förnya mitt medlemskap i {{company.name}} för {{period.name}}</h3>
                <b-row v-if="isOk === null">
                    <b-col><b-button class="mr-5" variant="success" @click="isOk = true">Ja</b-button></b-col>
                    <b-col><b-button @click="isOk = false"> {{$t('COMMON.CANCEL')}}</b-button></b-col>
                </b-row>
                <div class="">
                    <h3 v-show="isOk === true">Tack för din förnyelse</h3>
                    <h3 v-show="isOk === false">Du kan bekräfta medlemskapet vid en senare tidpunkt</h3>
                </div>
            </div>

            <div class="h-100 d-flex align-items-center text-center flex-wrap justify-content-center" v-if="isAuthenticated === false">
                <h3>Kunde inte hämta medlemsdata</h3>
            </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <ShopMembershipSelect
          ref="ref_page_shop"
          :memberships="memberships"
          :extras="extras"
          :page="page"
          :age="age"
          @membershipClicked="membershipClicked"
          @shopItemsUpdated="shopItemsUpdated"
          @isValid="isValid"
          @setData="setData"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-button type="button" variant="primary" @click="toPayment" :disabled="disabledPaymentButton">Till betalning</b-button>
      </b-col>
    </b-row>
    
  </div>
</template>


<style lang="scss" scoped>


.form-create-member {
  margin: 0 auto;
  padding: 25px;
}
.login-body {
  max-width: 500px;
  max-height: 300px;
  width: 100%;
  height: 100%;
}
</style>

<script>
import ShopMembershipSelect from '@/view/pages/members/register/registerwizard/ShopMembershipSelect.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'member-renew',
  mixins: [ toasts ],
  components: {
    ShopMembershipSelect
  },
  props: [ 'client' ],
  computed: {
    ...mapGetters(['isMemAuthenticated', 'currentMember'])
  },
  async mounted() {
    this.token = this.$route.params.token;

    this.loadRenewToken(this.token);
  },
  data() {
    return {
      memberships: [], // list of memberships
      extras: [], // list of extra items
      page: { id: 0, },
      age: 0,
      token: '',
      company: {},
      disabledPaymentButton: true
    };
  },
  watch: {
    
  },
  methods: {
    toPayment() {

      var shop = [];

      if (this.$refs.ref_page_shop !== undefined && this.$refs.ref_page_shop.length > 0) {
        shop = this.$refs.ref_page_shop[0].getData();
      }
      else if (this.$refs.ref_page_shop !== undefined) {
        shop = this.$refs.ref_page_shop.getData();
      }

      axios 
        .put('/client_renew/confirm', {
            shop: shop,
            client_id: this.client.client_id
        })
        .then(res => {

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte skapa förnyelsebetalning');
        });
      
    },

    setData(data, id) {

    },

    isValid(valid, id) {


      this.disabledPaymentButton = !valid;
    },

    shopItemsUpdated(obj) {

    },

    membershipClicked(membershipId) {

    },

    loadRenewToken(token) {
      axios 
        .get(`/client_renew/token?token=${token}`)
        .then(res => {

          this.loadRegistration(res.data.renew.company_id);
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta förnyelse');
          console.error(err);
        });  
    },

    loadNextRenew() {
      axios 
        .get(`/client_renew/next`)
        .then(res => {

        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte beräkna nästa förnyelse');
          console.error(err);
        });
    },

    loadRegistration(company_id) {
      
      axios
        .get(`/company/public/${company_id}`)
        .then(res => {
          this.company = res.data;
          
          this.memberships = [];
          this.extras = [];

          this.company.shop_items.filter(item => {
            if (item.is_member_fee && Boolean(item.is_active)) this.memberships.push(item);
            if (!item.is_member_fee && Boolean(item.is_active)) this.extras.push(item);
          })

        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta butiksartiklar');
        });
    },
  }
};
</script>
